import { get } from 'svelte/store'
import { baseUrl, listCode } from '$/stores/store';
import httpRequest from '$/utils/httpRequest';

const { env } = __app

async function subscribe(data) {
  const serverUrl = get(baseUrl)
  const url = `${serverUrl}/${env.SUBSCRIBE_URL}`.replace('<list_code>', get(listCode));
  const res = await httpRequest({
    url,
    method: env.SUBSCRIBE_METHOD,
    data,
  });
  
  return res.data;
}

export default subscribe;
