export default function (translationData) {
  return {
    button: {
      labelText: 'Testo',
      label: translationData.buttonLabel || 'Iscriviti!',
      style: 'Stile del bottone',
      hoverStyle: 'Stile hover',
      copyAttributes: 'Copia attributi da stile button'
    },
    input: {
      invalidError: 'Il valore fornito non è valido',
      required: 'Richiesto',
      requiredError: 'Questo campo è richiesto',
      style: 'Stile dei campi',
      mandatoryAlternativeError: 'Assicurati di inserire almeno la mail o il numero di telefono',
      vatCountryMandatoryError: 'È necessario prima selezionare un codice paese.',
      invalidVatCfError: 'Il valore deve essere alfanumerico e contenere fino a 20 caratteri.',
      invalidItVatCfError: 'Il valore deve essere composto da 11 cifre (Partita IVA) o 16 caratteri alfanumerici (CF).',
      invalidItVatError: 'Il valore deve essere composto da 11 cifre.',
      invalidItCfError: 'Il valore deve essere composto da 16 caratteri alfanumerici'
    },
    select: {
      date: "Seleziona data",
      phonePrefix: "Seleziona nazione"
    },
    output: {
      code: 'Codice HTML',
      copy: 'Copia codice',
      html_only: 'Solo HTML'
    },
    fields: {
      cant_exclude: "richiesto",
      excluded: 'Campi esclusi',
      manage_visibility: 'Gestisci visibilità',
      system_field: 'Campo di sistema',
      default_value: 'Valore di default',
      default_value_instructions : 'Qui puoi impostare campi con valori predefiniti, \
      che verranno assegnati di default a tutti gli utenti che popolano la tua lista \
      rimanendo nascosti durante la compilazione. Sono utili per aggiungere informazioni \
      funzionali per la successiva gestione dei tuoi contatti una volta inseriti nella lista. \
      Esempio: puoi fornire un valore predefinito a scelta multipla che permette di \
      assegnare uno stato agli utenti dopo N giorni all\'interno di una lista: "cliente" \
      se ha effettuato acquisti oppure "abbandona" se è diventato un cliente che non interagisce.'
    },
    messages: {
      confirmation_message: 'Messaggio di conferma',
      optin_confirmation_message: 'Messaggio di conferma per opt in',
      update_confirmation_message: 'Messaggio di conferma per aggiornamento preferenze',
      thank_you_page: 'Pagina di ringraziamento',
      thank_you_page_help_valid_url: 'Assicurati di inserire un URL valido',
      thank_you_page_help_priority: 'Se viene fornito l\'URL della pagina di ringraziamento, questo avrà la precedenza sul messaggio di conferma'
    },
    subscription: {
      ok: 'Iscrizione avvenuta con successo.',
      error: {
        address: {
          already_exists: 'Siamo spiacenti, questa e-mail è già nella lista'
        },
        phone_number: {
          already_exists: 'Siamo spiacenti, questo numero di telefono è già nella lista'
        }
      }
    },
    generic: {
      clear: 'Re-imposta',
      error: 'Errore',
      code_copied: 'Codice copiato!',
      design: 'Design',
      fields: 'Campi',
      messages: 'Messaggi',
      customize: 'Personalizza il form',
      output: 'Risultato',
      preview: 'Anteprima',
      code: 'Esporta',
      save: 'Salva',
      alignment: 'Allineamento',
      width: 'Larghezza',
      borderColor: 'Colore bordo',
      borderWidth: 'Spessore bordo',
      borderRadius: 'Curvatura bordo',
      backgroundColor: 'Colore sfondo',
      selectItemHoverColor: 'Colore sfondo opzioni select all\'hover',
      selectItemActiveColor: 'Colore sfondo opzioni select selezionate',
      selectItemColor: 'Colore testo opzioni select',
      textColor: 'Colore testo',
      validation: {
        test: 'toggle preview validation',
      },
      padding: {
        y: 'Padding verticale',
        x: 'Padding orizzontale',
      },
      margin: {
        y: 'Margine verticale',
      },
      font: {
        size: "Dimensione testo"
      },
      email: 'Email',
      phone_number: 'Numero di telefono'
    }
  }
}
