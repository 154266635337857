import readSubscriber from "../services/readSubscriber";
import getFormFields from "../services/getFormFields";
import {ServerError} from "./exceptions";
import {shutdown} from "../stores/store";

export default async function (messageKey) {
  try {
    let subscriber = null
    let fields = []
  
    if (![undefined, null, 'undefined', 'null'].includes(messageKey)) subscriber = await readSubscriber();
    fields = await getFormFields(subscriber);
    
    return {
      subscriber,
      fields
    }
  } catch (err) {
    if (err instanceof ServerError) {
      shutdown.set(true)
    }
  }
}
