import { get } from 'svelte/store'
import { baseUrl, messageKey } from '$/stores/store';
import httpRequest from '$/utils/httpRequest';

const { env } = __app

async function read(data) {
    const serverUrl = get(baseUrl)
    const url = `${serverUrl}/${env.SUBSCRIBE_READ_URL}`.replace('<message_key>', get(messageKey));
    const res = await httpRequest({
        url,
        method: env.SUBSCRIBE_READ_METHOD,
        data,
    });

    return res.data;
}

export default read;
