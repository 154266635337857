import App from '@/km_lists/embedded/App.svelte'

const { env } = __app

const target = env.FORM_PUBLIC_ELEMENT_ID;

// Initialize variable to track the embedded form index
window.KM__embeddedForm__formIndex = window.KM__embeddedForm__formIndex || 0;
let el;

// Get forms with the specified class name and then check if there are elements
// with the specified class and index
const els = document.getElementsByClassName(target);
if (els.length && els[window.KM__embeddedForm__formIndex]) {
	// Assign the target element to 'el'
	el = els[window.KM__embeddedForm__formIndex]
}

// If 'el' is not found by class name, try to find it by ID (which is the old implementation)
if (!el) {
	el = document.getElementById(target);
}

let app

if (el) {
	const targetDataset = el.dataset;
	const baseUrl = targetDataset.baseUrl;
	const embeddedFormId = targetDataset.embeddedFormId;
	const listCode = targetDataset.listCode;
	const messageKey = targetDataset.messageKey;
	const currentFormIndex = window.KM__embeddedForm__formIndex;

	app = new App({
		target: el,
		props: {
			targetDataset,
			baseUrl,
			embeddedFormId,
			listCode,
			messageKey,
			currentFormIndex
		}
	});
	// increment the form index after rendering target element
	window.KM__embeddedForm__formIndex++
}

export default app;
