<script>
	import { custom } from '$/stores/store'
	import _ from '$/utils/translations'
	import { KEY_CODES } from '$/utils/keyCodes'
	import { PHONE_PREFIX } from '$/data/phone_prefix.js';
	import Select from '@/ui/Select.svelte'
	import { onMount, createEventDispatcher } from 'svelte';
	import DatePicker from "./DatePicker.svelte";
	import InputTaxCode from '@/ui/InputTaxCode.svelte'

	// Data
	let errorMessage = false;
	let inputRef = null;
	let inputPrefixRef = null;

	// Event dispatcher
	const dispatch = createEventDispatcher()

	// Props
	export let value;
	export let label;
	export let name;
	export let placeholder = '';
	export let type;
	export let required;
	export let options = [];
	export let min;
	export let max ;
	export let maxlength ;
	export let apiError = false;
	export let execError = false;
	export let execErrorMessage = '';
	export let subscriber;
	export let visualizationMode;
	export let currentFormIndex;

	// Reactive statements
	$: inputPrefix = inputPrefixRef?.value?.toString() || ''
	$: hasError = errorMessage || execError
	$: value, dispatchChangeValue(value)

	const multipleSelectFields = ['MCHOICE', 'COUNTRIES_MCHOICE', 'ITALIAN_STATES_MCHOICE']
	const radio = 'RADIO'
	const checkbox = 'CHECKBOX'
	let valuesSelected = []

	// Methods
	const handleRadioOrCheckboxChoiceSelect = e => {
		if (['MCHOICE', 'ITALIAN_STATES_MCHOICE', 'COUNTRIES_MCHOICE'].includes(type)) {
			if (value) {
				Array.from(value).forEach(x => {
					if (!valuesSelected.includes(x)) {
						valuesSelected.push(x)
					}
				})
			}
			const optionValue = e.target.value;
			if (valuesSelected.includes(optionValue)) {
				valuesSelected = valuesSelected.filter(val => val !== optionValue);
			} else {
				valuesSelected.push(optionValue);
			}
			value = valuesSelected;
		} else if (['SCHOICE', 'ITALIAN_STATES_SCHOICE', 'COUNTRIES_SCHOICE'].includes(type)) {
			value = e.target.value;
		}
	};

	function dispatchChangeValue(value) {
		dispatch('change', { value })
	}

	const handleInput = e => {
		let res;
		const targetVal = (e.target.value) ? e.target.value : null;

		// do not validate when the value is null
		if (type === 'PHONE_NUMBER' && targetVal !== null) {
			if (!inputPrefix) {
				inputRef.value = ''
				e.preventDefault()
				return
			}

			if (targetVal.length < inputPrefix.length + 1/*+*/ + 1/*space*/) {
				e.preventDefault()
				inputRef.value = `+${inputPrefix} `
				return
			} else {
				res = targetVal
			}
		} else {
			res = targetVal;
		}

		value = res;
	};

	function getLabelFromValue(value) {
		return PHONE_PREFIX.find(item => item.value === parseInt(value));
	}


	const handleBlur = e => {
		if (type === 'PHONE_NUMBER') {
			const cleanValue = value && value.replace(/\D/g,'')
			const cleanPrefixValue = inputPrefixRef && inputPrefixRef.value || ''
			if (cleanValue === cleanPrefixValue.toString()) {
				// phone number reset
				value = ''

				// phone number prefix reset
				if (inputPrefixRef) {
					inputPrefixRef.value = null
					inputPrefixRef.label = ''
				}
			}
		}
	};

	const handleChoiceSelect = e => {
		if (type === 'PHONE_NUMBER') {
			if ($custom['html-only']) {
				value = e.target.value ? `+${e.target.value} ` : ''
			} else {
				value = e.detail?.value ? `+${e.detail.value} ` : ''
			}
			document.getElementById(`${inputRef.name}-${currentFormIndex}`).focus()
		} else {
			if ($custom['html-only']) {
				// using standart html select trigger a different
				if (multipleSelectFields.includes(type)) {
					const valuesSelected = []
					Array.from(e.target.selectedOptions).forEach(option => {
						if (option.value) {
							valuesSelected.push(option.value)
						}
					})
					value = valuesSelected
				} else {
					value = e.target.value
				}
			} else {
				value = multipleSelectFields.includes(type) ?
						e.detail.map(v => v.value) :
						e.detail.value
			}
		}
	}

	const handleChoiceClear = e => {
		if (value && multipleSelectFields.includes(type)) {
			value = value.filter(val => val !== e.detail.value)
		} else {
			value = null
		}
	}

	const filterInput = e => {
		const keyCode = ('which' in e) ? e.which : e.keyCode;

		if (type === 'INTEGER') {
			if (KEY_CODES.numericExtras.includes(keyCode)) {
				e.preventDefault()
			}
		} else if (type === 'PHONE_NUMBER') {
			const allowed = [
				...KEY_CODES.numbers,
				...KEY_CODES.edit,
				...KEY_CODES.motion,
			]

			// caret positioning handling
			if (e.target.selectionStart < inputPrefix.length + 3) {
				if (keyCode === KEY_CODES.left) {
					e.preventDefault()
				} else {
					e.target.selectionStart = value ? value.length + 2 : inputPrefix.length
				}
			}

			if (!allowed.includes(keyCode) || (!inputPrefix && keyCode !== KEY_CODES.tab)) {
				e.preventDefault()
			}
		}
	};

	const filterPaste = e => {
		/*
			Possible implementation.

			const clipboardData = e.clipboardData || window.clipboardData;
			const pastedData = clipboardData.getData('Text')

			// do something, eg
			if (...pastedData condition...) {
				e.stopPropagation();
				e.preventDefault();
			}
		*/
}

	function inputType (type) {
		if (type === 'EMAIL') return 'email';
		if (type === 'STRING') return 'text';
		if (type === 'INTEGER') return 'number';
		if (type === 'PHONE_NUMBER') return 'text';
		if (type === 'TEXT') return 'textarea';
		// if (type === 'SCHOICE') return 'radio';
		// if (type === 'MCHOICE') return 'checkbox';
		// if (type === 'ITALIAN_STATES_SCHOICE') return 'radio';
		// if (type === 'ITALIAN_STATES_MCHOICE') return 'checkbox';
		// if (type === 'COUNTRIES_SCHOICE') return 'radio';
		// if (type === 'COUNTRIES_MCHOICE') return 'checkbox';
		// if (type === 'DATE') return 'text';
		// if (type === 'CHECKBOX') return 'checkbox';
	}

	function inputPattern (type) {
		// if (type === 'PHONE_NUMBER') return '[0-9]{3}-[0-9]{3}-[0-9]{4}';
	}

	export const validate = () => {
		// set no errors (clean validation)
		errorMessage = null;

		// validate
		if (apiError) {
			errorMessage = _('input.invalidError');
		}

		if (execError) {
			errorMessage = execError
		}

		if (required && (!value || (Array.isArray(value) && !value.length))) {
			errorMessage = _('input.requiredError');
		}

		if (errorMessage === null && inputRef) {
			if (inputRef.checkValidity() === false) {
				errorMessage = _('input.invalidError');
			}
		}

		return errorMessage === null;
	};
	onMount(() => {
		const languageParts = navigator.language.split('-');
		const countryCode = languageParts[1] ? languageParts[1].toLowerCase() : languageParts[0].toLowerCase();

		if (type === 'PHONE_NUMBER' && countryCode && !value) {
			if (required) {
				const prefixObj = PHONE_PREFIX.find(x => x.countryCode === countryCode);
				if (prefixObj) {
					inputPrefix.$set({'value': PHONE_PREFIX.find(x => x.countryCode == countryCode)})
					value = `+${prefixObj.value} `;
				}
			}
		}
	});
</script>

<div class="{ !$custom['html-only'] ? 'km-input-container' : ''}">
	{#if label && !['CHECKBOX', 'CONSENT'].includes(type)}
		<label
			for="{inputRef && inputRef.name}"
			class="{ !$custom['html-only'] ? 'km-form-label' : '' }"
			style="
				color: {(hasError) ? 'var(--error-color)': null};
			"
		>
			{label}
			{#if required}
				<span title={_('input.required')}>*</span>
			{/if}
		</label>
	{/if}

	{#if ['CHECKBOX', 'CONSENT'].includes(type)}
		<div class="{ !$custom['html-only'] ? 'km-form-checkbox' : '' }">
			<div
				style='display: flex'
				on:click={() => value = !value}>
				<input
					type="checkbox"
					name="{name}"
					bind:checked={value}>
				<label
					for="{name}"
					style="
						color: {(hasError) ? 'var(--error-color)': null};
					"
				>{@html label}</label>
			</div>
		</div>
	{/if}

	{#if type === 'DATE'}
		<div class="{!$custom['html-only'] ? 'km-form-date-input' : ''}">
			<DatePicker bind:selectedDate={value} bind:name={name} bind:currentFormIndex={currentFormIndex} />
		</div>
	{/if}

	{#if ['EMAIL', 'PHONE_NUMBER', 'STRING', 'INTEGER'].includes(type)}
		{#if type === 'PHONE_NUMBER'}
			<!--
				if the field type is PHONE_NUMBER and we have a subscriber it means that we are in update pref
				also, if the subscriber has a PHONE_NUMBER already saved, the number cannot be edited
			-->
			{#if subscriber && (subscriber.PHONE_NUMBER !== 'None' && subscriber.PHONE_NUMBER) && name === 'phone_number'}
				<div
					style="display: flex; flex-direction: row"
					class="select-phone {!$custom['html-only'] ? 'km-form-select' : ''}">
					<div class="km-form-select-phone">
						<Select
							name="phone_number_prefix"
							disabled={subscriber.PHONE_NUMBER !== null}
							standardHtmlSelect={$custom['html-only']}
							args={{
								searchable: true,
								items: PHONE_PREFIX,
								placeholder: _('select.phonePrefix'),
								inputAttributes: {name: 'prefixPhone'}
							}}
							value={getLabelFromValue(subscriber.PHONE_NUMBER_PREFIX)}
							bind:this={inputPrefix}
							on:clear={handleChoiceClear}
							on:change={handleChoiceSelect}
						/>
					</div>

					<input
						class="{!$custom['html-only'] ? 'km-form-input' : ''}"
						id="{`${inputRef && inputRef.name}-${currentFormIndex}`}"
						bind:this={inputRef}
						value={subscriber.PHONE_NUMBER}
						style="width: 100%"
						readonly
						name={name}
						autocomplete="off"
					/>
				</div>
			{:else}
				<div
					style="display: flex; flex-direction: row"
					class="select-phone {!$custom['html-only'] ? 'km-form-select' : ''}">
					<div class="km-form-select-phone">
						<Select
							name="phone_number_prefix"
							standardHtmlSelect={$custom['html-only']}
							args={{
								searchable: true,
								items: PHONE_PREFIX,
								placeholder: _('select.phonePrefix'),
								inputAttributes: {name: 'prefixPhone'}
							}}
							bind:this={inputPrefix}
							bind:value={inputPrefixRef}
							on:clear={handleChoiceClear}
							on:change={handleChoiceSelect}
						/>
					</div>

					<input
						class="{!$custom['html-only'] ? 'km-form-input' : ''}"
						id="{`${inputRef && inputRef.name}-${currentFormIndex}`}"
						bind:this={inputRef}
						value={value}
						placeholder={placeholder}
						required={required}
						type={inputType(type)}
						pattern={inputPattern(type)}
						name={name}
						autocomplete="off"
						on:input={handleInput}
						on:paste={filterPaste}
						on:keydown={filterInput}
						on:blur={handleBlur}
					/>
				</div>
			{/if}
		{:else if type === 'EMAIL' && subscriber && subscriber.EMAIL}
			<input
				class="{!$custom['html-only'] ? 'km-form-input' : ''}"
				id="{inputRef && inputRef.name}"
				bind:this={inputRef}
				value={value}
				placeholder={placeholder}
				type={inputType(type)}
				readonly
				name={name}
			/>
		{:else}
			<input
				class="{!$custom['html-only'] ? 'km-form-input' : ''}"
				id="{inputRef && inputRef.name}"
				bind:this={inputRef}
				value={value}
				placeholder={placeholder}
				required={required}
				type={inputType(type)}
				pattern={inputPattern(type)}
				name={name}
				autocomplete="off"
				on:input={handleInput}
				on:paste={filterPaste}
				on:keydown={filterInput}
				on:blur={handleBlur}
				min={min}
				max={max}
				maxlength={maxlength}
			/>
		{/if}
	{/if}

	{#if type === 'TEXT'}
		<textarea
			class="{!$custom['html-only'] ? 'km-form-textarea' : ''}"
			bind:this={inputRef}
			value={value}
			placeholder={placeholder}
			required={required}
			type={inputType(type)}
			name={name}
			maxlength={maxlength}
			on:input={handleInput}
		></textarea>
	{/if}

	{#if ['SCHOICE', 'MCHOICE', 'ITALIAN_STATES_SCHOICE', 'ITALIAN_STATES_MCHOICE', 'COUNTRIES_SCHOICE', 'COUNTRIES_MCHOICE'].includes(type)}
		{#if !visualizationMode}
			<div class="{!$custom['html-only'] ? 'km-form-select' : ''}">
			<Select
				name={name}
				required={required}
				standardHtmlSelect={$custom['html-only']}
				args={{
					searchable: true,
					items: options,
					inputAttributes: {name},
					multiple: ['MCHOICE', 'ITALIAN_STATES_MCHOICE', 'COUNTRIES_MCHOICE'].includes(type)
				}}
				bind:value={value}
				on:clear={handleChoiceClear}
				on:change={handleChoiceSelect}
			/>
		</div>
		{:else}
			{#each options as option}
			 {#if ['SCHOICE', 'ITALIAN_STATES_SCHOICE', 'COUNTRIES_SCHOICE'].includes(type) && visualizationMode === radio}
				<div>
				 <div class="radio-group">
					<label>
						<input
							type="radio"
							name={name}
							checked={value === option}
							bind:value={option}
							on:change={handleRadioOrCheckboxChoiceSelect}
						/>
						{option}
					</label>
				 </div>
				</div>

			 {:else if ['MCHOICE', 'ITALIAN_STATES_MCHOICE', 'COUNTRIES_MCHOICE'].includes(type) && visualizationMode === checkbox}
				<div class="checkbox-group">

				 <label>
					<input
						type="checkbox"
						name={name}
						checked={value && value.includes(option)}
						bind:value={option}
						on:change={handleRadioOrCheckboxChoiceSelect}
					/>
					{option}
				 </label>
				</div>
			 {/if}
			{/each}
		{/if}

	{/if}

    {#if ['VAT', 'CF', 'VAT_CF'].includes(type)}
			<InputTaxCode
				accessors={true}
				currentFormIndex={currentFormIndex}
				name={name}
				typeInputTax={type}
				bind:value={value}
				bind:this={inputRef}
				on:clear={handleChoiceClear}
				on:change={handleChoiceSelect}
			/>
	{/if}

	{#if errorMessage || execErrorMessage}
		<span
			class="error-message"
			style="
				color: {(hasError) ? 'var(--error-color)' : 'var(--input-border-color)'};
			"
		>
			{errorMessage || execErrorMessage}
		</span>
	{/if}
</div>

<style>
	.km-form-input,
	.km-form-textarea,
	:global(.km-form-date-input .flatpickr input) {
		color: var(--input-text-color);
		border-radius: var(--input-border-radius);
		border-width: var(--input-border-width);
		border-color: var(--input-border-color);
		background-color: var(--input-background-color);
		font-size: 14px;
	}

	.km-form-select input {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.km-form-label {
		font-weight: bold;
	}

	.km-form-checkbox {
		cursor: pointer;
		margin-top: 15px;
	}

	.km-form-checkbox label {
		margin-left: 10px;
		font-style: italic;
	}


	.km-input-container {
		margin-top: 15px;
	}

	.km-input-container label {
		display: block;
		margin-top: 10px;
		margin-bottom: 5px;
		font-size: 14px;
	}

	.km-input-container input[type=text],
	.km-input-container input[type=email],
	.km-input-container input[type=number],
	.km-input-container input[type=date],
	.km-input-container input[type=tel],
	.km-input-container textarea {
		display: inline-block;
		border-style: solid;
		padding: 6px 12px;
		outline: none;
		width: 100%;
		box-shadow: none;
		box-sizing: border-box;
	}

	.km-input-container textarea {
		min-height: 100px;
		resize: vertical;
	}

	.km-input-container .error-message {
		font-size: 12px;
	}

	::placeholder {
		color: var(--input-text-color);
		opacity: 1; /* Firefox */
	}
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: var(--input-text-color);
	}
	::-ms-input-placeholder { /* Microsoft Edge */
		color: var(--input-text-color);
	}

	.km-clear-input > span {
		font-size: 12px;
		margin-top: 5px;
		float: right;
	}
	.km-clear-input > span:hover {
		cursor: pointer;
		text-decoration: underline;
	}
</style>