const vatcfRegex = {
    // regex pattern for italian VAT numbers, matches exactly 11 digits
    itVatRegex: /^[0-9]{11}$/,
    // regex pattern for italian fiscal code(CF):
    // 6 letters (surname), 2 digits (year), 1 letter (month), 2 digits (day), 1 letter (control character), 3 digits (municipality code), 1 letter (check character)
    itCfRegex: /^[A-z]{6}[0-9]{2}[A-z]{1}[0-9]{2}[A-z]{1}[0-9]{3}[A-z]{1}$/,
    // regex pattern for general VAT/CF, matches a string of 5 to 20 alphanumeric characters
    generalVatCfRegex: /^[A-z]{2}-[A-z0-9]{5,20}$/
}

Object.freeze(vatcfRegex) 
export const VATCF_REGEX = vatcfRegex
