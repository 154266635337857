import { get } from 'lodash';
import en from './en';
import es from './es';
import it from './it';

const { env } = __app;

export const fallbackLang = 'en';
const currentLang = (window.LANGUAGE_CODE || navigator.language || navigator.userLanguage).split('-')[0];
const availableLangs = env.LANG_CODES.split(',');
export const userLang = (availableLangs.includes(currentLang)) ? currentLang : fallbackLang;

let translations;

const computeTranslations = function (translationData = {}) {
  translations = {
    en: en(translationData),
    es: es(translationData),
    it: it(translationData),
  }
}
computeTranslations();

const translate = (key) => {
  return get(translations, `${userLang}.${key}`) || key;
}

export default translate;

export const reloadTranslations = function (translationData) {
  computeTranslations(translationData);
}
