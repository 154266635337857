export const systemFields = Object.freeze([
    'address',
    'phone_number',
    'privacy'
])

export function fieldIsRequired(field) {
    return field.required
}

export function fieldIsActive (customizations, field) {
    let excludedFields = customizations['excluded-fields'];
    if (excludedFields && typeof excludedFields === 'string') {
        excludedFields = JSON.parse(excludedFields);
    }

    return fieldIsRequired(field) || !(excludedFields && excludedFields.map((field) => field.toLowerCase()).includes(field.variable.toLowerCase()));
}

export function fieldIsSystem (field) {
    return systemFields.includes(field.variable)
}

export function fieldDefaultValue(customizations, field) {
    /**
     * search in object customizations, in the property 'default-values',
     * if the field has a default value to return it
     * if haven't returns null
     */
    let defaultValues = customizations['default-values']
    if (defaultValues && typeof defaultValues === 'string') {
        defaultValues = JSON.parse(defaultValues)
    }

    defaultValues = typeof defaultValues != 'object' ? {} : defaultValues
    const fieldName = field.variable.toUpperCase()
    if (Object.prototype.hasOwnProperty.call(defaultValues, fieldName)) {
        let value = defaultValues[fieldName]
        return value == undefined ? null : value
    }
        
    return null
}

export function canSetDefaultValueHiddenToField(customizations, field) {
    /**
     * check if a field can be set as non visible and with a value given by default, like an hidden
     * input, useful to populate virtual lists
     */
    return [
            !['CONSENT', 'VAT', 'CF', 'VAT_CF'].includes(field.type), // Do not a CONSENT fields
            !(field.type == 'privacy'), // Do not the field to privacy consent
            fieldIsActive(customizations, field), // Field must be active
            field.required === false  // Field do not be a required
        ].every((x) => x)
     
}