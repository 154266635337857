import axios from 'axios';
import { ServerError } from '$/utils/exceptions'

async function httpRequest(params) {
  const { url, method, headers, data } = params;

  if (!url) throw 'httpRequest: "url" is required';
  if (!method) throw 'httpRequest: "method" is required';
  
  const reqData = { url, method }
  if (data) reqData.data = data;
  if (headers) reqData.headers = headers;

  try {
    return await axios(reqData);
  } catch (err) {
    const status = err?.response?.status
    
    // network issue or server error
    if (!status || 500 <= status && status < 600) {
      console.error(err.message)
      throw new ServerError(err)
    }
    
    // api error
    if (400 <= status && status < 500) {
      throw err.response.data
    }

    throw Error('unknown error');
  }
}

export default httpRequest;
