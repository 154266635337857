// Default form settings
// Note: for the color picker to pick the color, use the full hex color code
// (#ffffff is OK, #fff is not).
export const settings = {
	'select-item-hover-bg': '#e7f2ff',
	'select-item-is-active-bg': '#007aff',
	'select-item-color': '#000000',
	'input-border-radius': 6,
	'input-border-radius-unit': 'px',
	'input-border-width': 1,
	'input-border-width-unit': 'px',
	'input-border-color': '#b0b0b0',
	'input-background-color': '#f2f2f2',
	'input-text-color': '#000000',
	'button-width': 100,
	'button-width-unit': '%',
	'button-alignment': 'center',
	'button-font-size': 18,
	'button-font-size-unit': 'px',
	'button-text-color': '#ffffff',
	'button-background-color': '#919191',
	'button-border-color': '#7a7a7a',
	'button-border-width': 2,
	'button-border-width-unit': 'px',
	'button-padding-y': 10,
	'button-padding-y-unit': 'px',
	'button-margin-y': 20,
	'button-margin-y-unit': 'px',
	'button-hover-width': 100,
	'button-hover-width-unit': '%',
	'button-hover-alignment': 'center',
	'button-hover-font-size': 18,
	'button-hover-font-size-unit': 'px',
	'button-hover-text-color': '#ffffff',
	'button-hover-background-color': '#919191',
	'button-hover-border-color': '#7a7a7a',
	'button-hover-border-width': 2,
	'button-hover-border-width-unit': 'px',
	'button-hover-padding-y': 10,
	'button-hover-padding-y-unit': 'px',
	'button-hover-margin-y': 20,
	'button-hover-margin-y-unit': 'px',
	'error-color': "#de4a4a",
	'button-label': {
		'it': 'Iscriviti',
		'en': 'Subscribe',
		'es': 'Subscribir',
	},
	'button-label-update': {
		'it': 'Aggiorna preferenze',
		'en': 'Update preferences',
		'es': 'Actualizar preferencias',
	},
	'excluded-fields': [],
	'default-values' : {},
	'thank-you-page': '',
	'confirmation-message': {
		'it': 'A breve riceverai un\'email che conterrà il link per confermare l\'iscrizione!. Se non ricevi l\'email in un paio di minuti, controlla nella casella di spam',
		'en': 'You\'re going to receive an email with a link that will let you confirm the subscription!. If you do not receive the mail withing a couple of minutes, please check in the spam / junk folder',
		'es': '¡Recibirá un correo electrónico con un enlace que le permitirá confirmar la suscripción!. Si no recibes el email dentro de un par de minutos, por favor comprueba la carpeta de spam/junk'
	},
	'update-confirmation-message': {
		'it': 'Le tue informazioni sono state aggiornate!',
		'en': 'Your information has been updated!',
		'es': '¡Su información ha sido actualizada.!'
	},
	'optin-confirmation-message': {
		'it': 'Grazie per esserti iscritto!',
		'en': 'Thank you for subscribing!',
		'es': '¡Gracias por suscribirse!'
	},
	'html-only': false
}
