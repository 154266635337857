<script>
	import { createEventDispatcher } from 'svelte';
	import { custom } from '$/stores/store';

	export let buttonLabel;
	export let buttonId = null;

	const dispatch = createEventDispatcher();

	function handleClick (event) {
		event.preventDefault();
		dispatch('click',event.target);
	}
</script>
{#if $custom['html-only']}
	<button id={buttonId} type="button" on:click={handleClick}>
		{buttonLabel}
	</button>
{:else}
	<div id={buttonId} class="km-form-button-wrapper">
		<div
			href="/"
			class="km-form-button"
			on:click={handleClick}>{buttonLabel}</div>
	</div>
{/if}

<style>
.km-form-button-wrapper {
	display: flex;
	justify-content: var(--button-alignment);
}

.km-form-button {
	cursor: pointer;
	text-align: center;
	border-style: solid;
	font-size: var(--button-font-size);
	color: var(--button-text-color);
	background-color: var(--button-background-color);
	border-radius: var(--button-border-radius);
	border-color: var(--button-border-color);
	border-width: var(--button-border-width);
	margin-top: var(--button-margin-y);
	margin-bottom: var(--button-margin-y);
	padding-top: var(--button-padding-y);
	padding-bottom: var(--button-padding-y);
	width: var(--button-width);
}
.km-form-button:hover {
	font-size: var(--button-hover-font-size);
	color: var(--button-hover-text-color);
	background-color: var(--button-hover-background-color);
	border-radius: var(--button-hover-border-radius);
	border-color: var(--button-hover-border-color);
	border-width: var(--button-hover-border-width);
	margin-top: var(--button-hover-margin-y);
	margin-bottom: var(--button-hover-margin-y);
	padding-top: var(--button-hover-padding-y);
	padding-bottom: var(--button-hover-padding-y);
	width: var(--button-hover-width);
}
</style>
