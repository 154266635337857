<script>
  import flatpickr from 'flatpickr'
  import { onDestroy, onMount } from 'svelte'
  import _ from '$/utils/translations'
  import { currentLangCode } from '$/stores/store'

  import DatePickerItalian from 'flatpickr/dist/l10n/it'
  import DatePickerSpanish from 'flatpickr/dist/l10n/es'
  import DatePickerEnglish from 'flatpickr/dist/l10n/default'
  import { format } from 'date-fns-tz'

  export let selectedDate = ''
  export let currentFormIndex
  export let name

  let flatpickrInstance

  // Reactive statements
  $: datePickerLocale = () => {
    if ($currentLangCode === 'it') return DatePickerItalian
    if ($currentLangCode === 'en') return DatePickerEnglish
    if ($currentLangCode === 'es') return DatePickerSpanish
  }
  $: datePickerFormat = () => {
    if ($currentLangCode === 'it') return 'd/m/Y'
    if ($currentLangCode === 'en') return 'Y-m-d'
    if ($currentLangCode === 'es') return 'd/m/Y'
  }

  function dateChanged(e) {
    let currentSelectedDate = e.target.value
    const pattern = 'yyyy-MM-dd'
    if (!currentSelectedDate || currentSelectedDate === '') {
      selectedDate = null
    } else {
      selectedDate = format(new Date(currentSelectedDate), pattern, {
        timeZone: 'Europe/Rome'
      })
    }
  }

  onMount(() => {
    // Initialize Flatpickr with form-specific options when the component is mounted
    flatpickrInstance = flatpickr(`#date-picker-${name}-${currentFormIndex}`, {
      mode: 'single',
      locale: datePickerLocale(),
      altInput: true,
      enableTime: false,
      altFormat: datePickerFormat(),
      dateFormat: 'Y-m-d'
    })
  })

  onDestroy(() => {
    // destroy Flatpickr instance when the component is destroyed
    if (flatpickrInstance) {
      flatpickrInstance.destroy()
    }
  })

  function clearDate() {
    flatpickrInstance.clear()
    selectedDate = ''
  }
</script>

<div class="flatpickr datepicker-container">
  <input
    data-input
    type="text"
    id={`date-picker-${name}-${currentFormIndex}`}
    bind:value={selectedDate}
    placeholder={_('select.date')}
    on:change={dateChanged}
  />
  {#if selectedDate}
    <div class="clear-button" data-clear on:click={clearDate}>
      {_('generic.clear')}
    </div>
  {/if}
</div>

<style>
  .datepicker-container {
    position: relative;
  }

  input[type='text'] {
    display: inline-block;
    border-style: solid;
    padding: 6px 12px;
    outline: none;
    width: 100%;
    box-shadow: none;
    box-sizing: border-box;
  }

  .clear-button {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    padding: 5px;
  }
</style>
