<script>
	import { getContext, tick } from 'svelte';

	import _ from '$/utils/translations'
	import { currentLangCode, fallbackLangCode, custom, shutdown } from '$/stores/store'
	import { getConfigFromDataset } from '$/utils/dataAttributes.js'
	import { settings } from '~/settings.js'
	import subscribe from '$/services/subscribe'
	import updateSubscriber from '$/services/updateSubscriber'

	import Input from '@/km_lists/embedded/Input.svelte'
	import Button from '@/km_lists/embedded/Button.svelte'

	export let targetDataset;
	export let fields;
	export let subscriber;
	export let embeddedFormId;
	export let messageKey;
	export let currentFormIndex;
	import { fieldIsActive, fieldDefaultValue } from '$/utils/field'

	const isPublic = getContext('isPublic')

	if (isPublic) {
		if (targetDataset) {
			custom.set({
				...JSON.parse(JSON.stringify(settings)),
				...getConfigFromDataset(JSON.parse(JSON.stringify(targetDataset)))
			})
		}
	}

	let
		// if the whole form validates
		isValid = true,
		// if the single fields validates
		fieldValid = true,
		// subscription request success flag
		subscribeOk = false,
		// update preferences request success flag
		updateSubscriberOk = false,
		// show optin message
		showOptinMessage = true,
		// bot traps
		botCheckboxTrap1 = false,
		botCheckboxTrap2 = false,
		// user compiled data sent to the server
		payload = {
			// 'address': null,      // Master fields email
			// 'phone_number': null, // Master fields phone
			data: {
				// other fields
				// 'date': null,
				// 'countries': [],
				// etc...
			},
		},
		isInterchangeableEmailAndNotFilled = null,
		isInterchangeablePhoneAndNotFilled = null,
		// object that store various labels for each field, for internationalization
		fieldsLabel = {},
		// variable that store if the subscriber has email address
		hasEmail = false;
	$: get = (key, langCode) => {
		// Obtain the customized value or the default value (as fallback) given
		// a customization key and eventually a language code (for localized
		// data like, button label etc)
		if (Object.keys($custom).length) {
			if (langCode) {
				if (['button-label-update', 'button-label', 'confirmation-message', 'update-confirmation-message', 'optin-confirmation-message'].includes(key)) {
					try {
						// Public site
						return JSON.parse($custom[key])[langCode]
					} catch {
						// admin Platform
						return JSON.parse(JSON.stringify($custom[key]))[langCode]
					}

				} else {
					return $custom[key][langCode]
				}
			} else {
				return $custom[key] + ($custom[`${key}-unit`] || '')
			}
		}
	}

	$: $currentLangCode, computeFieldsLabel()
	$: fields, computeFieldsLabel()

	function computeFieldsLabel() {
		fields.forEach((field) => {
			fieldsLabel[field.variable] = computeFieldLabel(field)
		})
	}

	// gets form index to append it to the recaptcha div id
	function computeRecaptchaId () {
		return `KM__embeddedForm__googleRecaptchaDiv${currentFormIndex}`;
	}


	function computeFieldLabel (field) {
		let label = field.label[$currentLangCode] || field.label[$fallbackLangCode] || field.name
		if (field.url) {
			label = `${label} <a href="${field.url}" target="_blank">&#128279;</a>`
		}
		return label
	}

	async function handleSubmit () {
		isValid = true;
		let currentUrl = window.location.href;
		let fieldWithDefaultValue
		// reset apiError and execError
		for (let i = 0; i < fields.length; i++) {
			fields[i].apiError = false;
			fields[i].execError = false;
		}
		await tick();

		isInterchangeableEmailAndNotFilled = false;
		isInterchangeablePhoneAndNotFilled = false;

		// validate dynamic data
		fields.forEach(field => {
			if (field.component) {
				fieldValid = field.component.validate();
				switch (field.variable) {
					case 'address':
						// if the field is required AND the field is not evaluated
						if (field.required === null && !field.value) isInterchangeableEmailAndNotFilled = true
						payload[field.variable] = field.value;
						break;
					case 'phone_number':
						if (field.required === null && !field.value) isInterchangeablePhoneAndNotFilled = true
						payload[field.variable] = field.value;
						break;
					default:
						payload.data[field.variable] = field.value
						break;
				}
				isValid = isValid && fieldValid;
			} else {
				// If is a field with the default value
				fieldWithDefaultValue = fieldDefaultValue($custom, field)
				if (fieldWithDefaultValue != null) {
					payload.data[field.variable] = fieldWithDefaultValue
				}
			}
		})

		// after the initial check for fields validity lets rearrange the values
		fields = fields.map((field) => {
			if (['address', 'phone_number'].includes(field.variable)) {
				field.execError = isInterchangeableEmailAndNotFilled && isInterchangeablePhoneAndNotFilled
				field.execErrorMessage = field.execError ? _('input.mandatoryAlternativeError') : ''
			}
			if(['VAT', 'CF', 'VAT_CF'].includes(field.type)) {
				// length = 3 is the length of the prefix (eg: IT-)
				// so if required and there is no value or the value is just the prefix
				// means its not valid
				if (field.required === false && field.value?.length === 3) {
					payload.data[field.variable] = null;
				}
			}
			return field
		})

		isValid = isValid && !(isInterchangeableEmailAndNotFilled && isInterchangeablePhoneAndNotFilled)

		// add to the payload the current url(used for the consent data) and
		// the embedded form id(used for tracking number of users who filled the form)
		payload['origin_url'] = currentUrl
		payload['embedded_form_id'] = embeddedFormId

		// add to the payload the checkboxes traps for bots
		payload.as_check1 = botCheckboxTrap1;
		payload.as_check2 = botCheckboxTrap2;

		// add language to payload
		payload.lang = $currentLangCode

		// don't invoke recaptcha if the form is invalid or not public
		if (isValid && isPublic) {
			const currentRecaptchaId = window.KM__embeddedForm__captchasId[currentFormIndex]
			grecaptcha.execute(currentRecaptchaId)
		}
	}

	// boolean function to determine whether the google recaptcha script has been executed,
	// we only want it to execute once so that no duplicate recaptcha instances are created
	function initScript () {
		if (typeof window.KM__embeddedForm__initScript === 'undefined') {
			window.KM__embeddedForm__initScript = true
			return true
		}
		return false
	}

	async function subscriptionApiCall (token) {
		if (!isPublic) return

		// subscription api call
		if (!token) {
			console.warn("devs: recaptcha token is NOT OK, check API key");
			return false;
		}

		if (!token && isValid) return

		try {
			const body = Object.assign({google_recaptcha_response: token}, payload)
			hasEmail = 'address' in body
			if (subscriber) await updateSubscriber(messageKey, body);
			else {
				const ret = await subscribe(body);
				showOptinMessage = ret.hasOwnProperty('double_optin') ? ret.double_optin : true
			}
			if ($custom['thank-you-page'] && $custom['thank-you-page'] !== '') {
				window.location.href = $custom['thank-you-page'];
			} else {
				if (subscriber) updateSubscriberOk = true;
				else subscribeOk = true;
			}
		} catch (err) {
			// if the API call goes wrong, the recaptcha should be reset
			// otherwise it will look like "its already been used"
			grecaptcha.reset()
			let refresh = false
			if (typeof err === 'object') {
				// Check for server hard errors
				if (err.message) {
					// es. 'Error: Request failed with status code 500'
					shutdown.set(true)
				}

				if (err.validation_errors) {
					Object.keys(err.validation_errors).forEach((x) => {
						const variable = x.replace('data_', '').replace(' ', '_')

						for (let i = 0; i < fields.length; i++) {
							if (fields[i].variable.toUpperCase() === variable.toUpperCase()) {
								fields[i].apiError = true;
								break;
							}
						}
					});
					refresh = true
				}

				if (err.execution_errors) {
					Object.keys(err.execution_errors).forEach((variable) => {
						for (let i = 0; i < fields.length; i++) {
							if (fields[i].variable === variable) {
								const err_code = err.execution_errors[variable][0]['code']
								fields[i].execError = _(`subscription.error.${variable}.${err_code}`)
								break;
							}
						}
					});
					refresh = true
				}

				if (refresh) {
					await tick();
					fields.forEach(field => {
						if (field.component) {
							fieldValid = field.component.validate();
						}
					})
				}
			}
		}
	}
	// assign the subscriptionApiCall function to global variable
	// KM__embeddedForm__recaptchaChallengeSolvedCallbacks as a workaround
  // for the scope issue encountered previously, this allows subscriptionApiCall to be
  // accessed and executed from the recaptcha callback after the challenge is solved
	window.KM__embeddedForm__recaptchaChallengeSolvedCallbacks = window.KM__embeddedForm__recaptchaChallengeSolvedCallbacks || {}
	window.KM__embeddedForm__recaptchaChallengeSolvedCallbacks[currentFormIndex] = subscriptionApiCall
</script>

<svelte:head>
	{#if initScript()}
		<script src="https://www.google.com/recaptcha/api.js?onload=kmRecaptchaOnloadCallback&render=explicit" async defer></script>
		<script>
			const { env } = __app;

			function kmRecaptchaOnloadCallback() {
				// setTimeout to introduce a delay, so we can be sure all elements have rendered before we make calculations on
				// the number of recaptchas we need to initialize for the embedded forms
				setTimeout(() => {
					let captchaDivs = Array.from(document.getElementsByTagName("div")).filter(x => x.id.includes('KM__embeddedForm__googleRecaptchaDiv'));

					window.KM__embeddedForm__captchasId = [];
					// loop through every div that needs recaptcha, render them and store their id
					// the callback is executed when the recaptcha challenge is solved, which then invokes the subscriptionApiCall method
					for (let i = 0; i < captchaDivs.length; i++) {
						const theId = captchaDivs[i].id;
						window.KM__embeddedForm__captchasId[i] = grecaptcha.render(theId, {
							sitekey: env.GOOGLE_RECAPTCHA_SITE_KEY,
							size: 'invisible',
							badge: 'bottomleft',
							callback: (token) =>  window.KM__embeddedForm__recaptchaChallengeSolvedCallbacks[i](token)
						});
						window.KM__embeddedForm__captchasId.push();
					}
				}, 500); // delay time
			}
		</script>

		<!-- include style for dates-->
		{#if fields.map(f => f.type).includes('DATE')}
			<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/flatpickr@4.6.13/dist/flatpickr.css'>
		{/if}
		
		<!-- include style for phone numbers-->
		{#if fields.map(f => f.type).includes('PHONE_NUMBER')}
			<style>
				.km-form-select-phone .svelte-select {
					min-width: 150px !important;
					max-width: 250px !important;
					border-right: none !important;
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: 0 !important;
				}
				.km-form-select-phone .list {
					width: 200px !important;
				}
			</style>
		{/if}
        {#if fields.filter(f => ['VAT', 'CF', 'VAT_CF'].includes(f.type.toUpperCase())).length }
            <style>
                .km-form-select-taxcode .svelte-select {
                    min-width: 150px !important;
                    max-width: 250px !important;
                    border-right: none !important;
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
                .km-form-select-taxcode .list {
                    width: 200px !important;
                }
            </style>    
        {/if}
    {/if}    
</svelte:head>

{#if $shutdown }
	<div class="server-error">
		{_('generic.error')}
	</div>
{:else if updateSubscriberOk }
	<div class="subscription-ok">{@html get('update-confirmation-message', $currentLangCode)}</div>
{:else if subscribeOk }
	<div class="subscription-ok">
		<!-- since the confirmation message with the optin link is sent via mail, the message with email references
		should be visible only in the case the email is passed -->
		{#if hasEmail && showOptinMessage}
			{@html get('confirmation-message', $currentLangCode)}
		{:else}
			{@html get('optin-confirmation-message', $currentLangCode)}
		{/if}
	</div>
{:else }
	<form
		autocomplete="off">
		{#if fields}
			{#each fields as field}
				{#if fieldIsActive($custom, field) && fieldDefaultValue($custom, field) == null}
					{#if field.intro_text && (field.intro_text[$currentLangCode] || field.intro_text[$fallbackLangCode])}
						<div class="km-form-intro-text">
							{@html field.intro_text[$currentLangCode] || field.intro_text[$fallbackLangCode]}
						</div>
					{/if}
					{#if $custom['html-only']}
					<Input
						bind:value={field.value}
						apiError={field.apiError}
						execError={field.execError}
						execErrorMessage={field.execErrorMessage}
						label={fieldsLabel[field.variable]}
						name={field.variable}
						type={field.type}
						required={field.required}
						options={field.options}
						subscriber={subscriber}
						visualizationMode={field.visualization_mode}
						bind:this={field.component}
						min={field.min}
						max={field.max}
						maxlength={field.maxlength}
					/>
					{:else}
					<Input
						bind:value={field.value}
						apiError={field.apiError}
						execError={field.execError}
						execErrorMessage={field.execErrorMessage}
						label={fieldsLabel[field.variable]}
						name={field.variable}
						type={field.type}
						required={field.required}
						options={field.options}
						min={field.min}
						max={field.max}
						maxlength={field.maxlength}
						subscriber={subscriber}
						visualizationMode={field.visualization_mode}
						bind:currentFormIndex={currentFormIndex}
						bind:this={field.component}
						--input-text-color={$custom['input-text-color']}
						--background={$custom['input-background-color']}
						--border={$custom['input-border-width'] + $custom['input-border-width-unit'] + ' solid ' + $custom['input-border-color']}
						--border-focus-color={$custom['input-border-color']}
						--border-hover-color={$custom['input-border-color']}
						--border-radius={$custom['input-border-radius'] + $custom['input-border-radius-unit']}
						--input-border-radius={$custom['input-border-radius'] + $custom['input-border-radius-unit']}
						--input-border-width={$custom['input-border-width'] + $custom['input-border-width-unit']}
						--input-background-color={$custom['input-background-color']}
						--input-border-color={$custom['input-border-color']}
						--error-color={$custom['error-color']}
						--clear-icon-color={$custom['input-border-color']}
					/>
					{/if}

					{#if field.help_text && (field.help_text[$currentLangCode] || field.help_text[$fallbackLangCode])}
						<div class="help-block">
							{ field.help_text[$currentLangCode] || field.help_text[$fallbackLangCode] }
						</div>
					{/if}
				{/if}
			{/each}

		<!--	bot checkbox traps -->
		<div class="km-additional-consent">
			<input type=checkbox bind:checked={botCheckboxTrap1}>
			<input type=checkbox bind:checked={botCheckboxTrap2}>
		</div>
		{#if isPublic }
			<div class="g-recaptcha" id={computeRecaptchaId()}></div>
		{/if}
		<!-- submit button -->
		<Button
			buttonLabel={get(subscriber ? 'button-label-update' : 'button-label', $currentLangCode)}
			on:click={() => handleSubmit()}
			--button-font-size={get('button-font-size')}
			--button-text-color={get('button-text-color')}
			--button-background-color={get('button-background-color')}
			--button-border-radius={get('input-border-radius')}
			--button-border-color={get('button-border-color')}
			--button-border-width={get('button-border-width')}
			--button-margin-y={get('button-margin-y')}
			--button-padding-y={get('button-padding-y')}
			--button-width={get('button-width')}
			--button-alignment={get('button-alignment')}
			--button-hover-font-size={get('button-hover-font-size')}
			--button-hover-text-color={get('button-hover-text-color')}
			--button-hover-background-color={get('button-hover-background-color')}
			--button-hover-border-radius={get('input-border-radius')}
			--button-hover-border-color={get('button-hover-border-color')}
			--button-hover-border-width={get('button-hover-border-width')}
			--button-hover-margin-y={get('button-hover-margin-y')}
			--button-hover-padding-y={get('button-hover-padding-y')}
			--button-hover-width={get('button-hover-width')}
			--button-hover-alignment={get('button-hover-alignment')}
		/>
	{/if}
	</form>
{/if}

 <style>
	.km-form-checkbox {
		cursor: pointer;
		margin-top: 15px;
	}
	.km-form-checkbox label {
		margin-left: 10px;
		font-style: italic;
	}
	.km-form-checkbox label.error-message,
	.km-form-checkbox span.error-message {
		color: rgb(222, 74, 74);
		font-size: 12px;
	}

	.km-additional-consent {
		display: none;
	}

	.km-form-intro-text {
		display: block;
		margin-top: 30px;
	}
</style>
