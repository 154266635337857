import { get } from 'svelte/store'
import { baseUrl } from '$/stores/store';
import httpRequest from '$/utils/httpRequest';

const { env } = __app

async function update(message_key, data) {
  const serverUrl = get(baseUrl)
  const url = `${serverUrl}/${env.SUBSCRIBE_READ_URL}`.replace('<message_key>', message_key);
  const dataWithSubKey = data
  dataWithSubKey['message_key'] = message_key
  const res = await httpRequest({
    url,
    method: env.SUBSCRIBER_UPDATE_METHOD,
    data: dataWithSubKey,
  });

  return res.data;
}

export default update;
