import { writable } from 'svelte/store'

import { userLang, fallbackLang } from '$/utils/translations'

const baseUrl = writable(null);
const custom = writable({});
const currentLangCode = writable(null);
const fallbackLangCode = writable(fallbackLang);
const listCode = writable(null);
const embeddedFormId = writable(null);
const messageKey = writable(null);
const shutdown = writable(false);

const reloadStore = function (newBaseUrl, newListCode, newEmbeddedFormId, newMessageKey) {
  let url
  if (newBaseUrl) {
    const currentPageUrl = new URL(window.location.href)
    const currentPageProtocol = currentPageUrl.protocol
    url = (newBaseUrl.startsWith('http')) ? newBaseUrl : `${currentPageProtocol}//${newBaseUrl}`
  }

  baseUrl.set(url);
  currentLangCode.set(userLang);
  listCode.set(newListCode);
  embeddedFormId.set(newEmbeddedFormId)
  messageKey.set(newMessageKey);
  shutdown.set(!(url && newListCode))
}

reloadStore();

// export store variables
export {
  baseUrl,
  custom,
  currentLangCode,
  fallbackLangCode,
  listCode,
  embeddedFormId,
  messageKey,
  shutdown,
  reloadStore
}