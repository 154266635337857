// Ref. https://keycode.info/
export const KEY_CODES = {
	// numeric extras
	e: 69, add: 107, addPad: 187, sub: 109, subPad: 189, period: 110,
	periodPad: 190,
	// motion
	tab: 9, end: 35, home: 36, left: 37, up: 38, right: 39, down: 40,
	// edit
	backspace: 8, ins: 45, del: 46,
}

KEY_CODES.numbers = [
	// keyboard numbers
	...[...Array(10).keys()].map(i => i + 48),
	// numeric pad numbers
	...[...Array(10).keys()].map(i => i + 96)
]

KEY_CODES.letters = [
    // uppercase letters
    ...[...Array(26)].map((_, i) => i + 65), // A-Z
    // lowercase letters
    ...[...Array(26)].map((_, i) => i + 97), // a-z
];

KEY_CODES.motion = [
	KEY_CODES.tab,
	KEY_CODES.end,
	KEY_CODES.home,
	KEY_CODES.left,
	KEY_CODES.up,
	KEY_CODES.right,
	KEY_CODES.down,
]

KEY_CODES.edit = [
	KEY_CODES.backspace,
	KEY_CODES.ins,
	KEY_CODES.del,
]

KEY_CODES.numericExtras = [
	KEY_CODES.e,
	KEY_CODES.add, KEY_CODES.addPad,
	KEY_CODES.sub, KEY_CODES.subPad,
	KEY_CODES.period, KEY_CODES.periodPad
]
