<script>
    import Select from 'svelte-select';
	import { custom } from '$/stores/store';

    export let value;
    export let args;
    export let name;
    export let required = false;
    export let standardHtmlSelect = false;
    export let disabled = false;

    // 14 is the font size, 6 is the vertical padding
    // $: height = `${14 + $custom['input-border-width']*2 + 6*2}px`
    $: height = '45px'
    $: border = `${$custom['input-border-width']}${$custom['input-border-width-unit']} solid ${$custom['input-border-color']}`
    $: borderRadius = `${$custom['input-border-radius']}${$custom['input-border-radius-unit']}`
</script>

{#if standardHtmlSelect }
    <select
        name={name}
        required={required}
        multiple={args.multiple}
        on:clear
        on:change
        on:select
    >
        {#if !Boolean(args.multiple)}
            <option value=""></option>
        {/if}
        {#each args.items as o}
            <!-- At the standart html select the event is di -->
            {#if typeof o === 'object' && Array.isArray(value)}
                <option selected={(value && value.includes(o.value))} value="{o.value}">
                    { o.label }
                </option>
            {:else if  typeof o === 'object'}
                <option selected={(value && value === o.value)} value="{o.value}">
                    { o.label }
                </option>
            {:else}
                <option selected={(value && value === o)} value="{o}">
                    { o }
                </option>
            {/if}
        {/each}
    </select>
{:else}
    <Select
        {value}
        {...args}
        disabled={disabled}
        on:clear
        on:change
        on:select
        --font-size="14px"
        --background={$custom['input-background-color']}
        --list-background={$custom['input-background-color']}
        --height={height}
        --placeholder-color={$custom['input-text-color']}
        --selected-item-color={$custom['input-text-color']}
        --border={border}
        --border-hover={border}
        --border-focused={border}
        --border-radius={borderRadius}
        --item-hover-bg={$custom['select-item-hover-bg']}
        --item-is-active-bg={$custom['select-item-is-active-bg']}
        --item-color={$custom['select-item-color']}
    />
{/if}
