export default function (translationData) {
  return {
    button: {
      labelText: 'Text',
      label: translationData.buttonLabel || 'Subscribe!',
      style: 'Button style',
      hoverStyle: 'Style hover',
      copyAttributes: 'Copy attributes from button style'
    },
    input: {
      invalidError: 'The value provided is not valid',
      required: 'Required',
      requiredError: 'This field is required',
      style: 'Field style',
      mandatoryAlternativeError: 'Make sure you include at least your email or phone number',
      vatCountryMandatoryError: 'A country code must be selected first.',
      invalidVatCfError: 'The value must be alphanumerical and up to 20 characters.',
      invalidItVatCfError: 'The value must be comprised of 11 digits (VAT) or 16 alphanumerical characters (CF).',
      invalidItVatError: 'The value must be comprised of 11 digits.',
      invalidItCfError: 'The value must be comprised of 16 alphanumerical characters.'
    },
    select: {
      date: "Select date",
      phonePrefix: "Select country"
    },
    output: {
      code: 'HTML code',
      copy: 'Copy code',
      html_only: 'HTML only'
    },
    fields: {
      excluded: 'Excluded fields',
      cant_exclude: "required",
      manage_visibility: 'Manage visibility',
      system_field: 'System field',
      default_value: 'Default value',
      default_value_instructions : 'Here you can set up fields with default values, \
      which will be assigned by default to all users who populate your list while \
      remaining hidden during compilation. They are useful for adding functional \
      information for later management of your contacts once they are within the list. \
      Example: you can provide a multiple-choice default value that allows you to assign \
      a status to users after N days within a list: "customer" if they have made purchases \
      or "abandon" if they have become a cold customer.'
    },
    messages: {
      confirmation_message: 'Confirmation message',
      optin_confirmation_message: 'Confirmation message for opt in',
      update_confirmation_message: 'Confirmation message for update preferences',
      thank_you_page: 'Thank you page',
      thank_you_page_help_valid_url: 'Ensure to enter a valid URL',
      thank_you_page_help_priority: 'If the thank you page url is given it will take precedence over the confirmation message'
    },
    subscription: {
      ok: 'Succesfull subscribed to the list.',
      error: {
        address: {
          already_exists: 'Sorry, this e-mail is already in the list of subscribers'
        },
        phone_number: {
          already_exists: 'Sorry, this phone number is already in the list of subscribers'
        }
      }
    },
    generic: {
      clear: 'Clear',
      error: 'Error',
      code_copied: 'Code copied!',
      design: 'Design',
      fields: 'Fields',
      messages: 'Messages',
      customize: 'Customize the form',
      output: 'Output',
      preview: 'Preview',
      code: 'Export',
      save: 'Save',
      alignment: 'Alignment',
      width: 'Width',
      borderColor: 'Border color',
      borderWidth: 'Border width',
      borderRadius: 'Border radius',
      backgroundColor: 'Background color',
      selectItemHoverColor: 'Select options background color on hover',
      selectItemActiveColor: 'Active select options background color on hover',
      selectItemColor: 'Select options color',
      textColor: 'Text color',
      validation: {
        test: 'toggle preview validation',
      },
      padding: {
        y: 'Vertical padding',
        x: 'Horizontal padding',
      },
      margin: {
        y: 'Vertical margin',
      },
      font: {
        size: "Text size"
      },
      email: 'Email',
      phone_number: 'Phone number'
    }
  }
}
