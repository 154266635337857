export default function (translationData) {
  return {
    button: {
      labelText: 'Texto',
      label: translationData.buttonLabel || 'Suscribir!',
      style: 'Estilo button',
      hoverStyle: 'Estilo hover',
      copyAttributes: 'Copiar atributos del estilo del botón'
    },
    input: {
      invalidError: 'El valor proporcionado no es válido',
      required: 'Necesario',
      requiredError: 'Este campo es requerido',
      style: 'Estilo campos',
      mandatoryAlternativeError: 'Asegúrate de incluir al menos tu correo electrónico o número de teléfono.',
      vatCountryMandatoryError: 'Primero se debe seleccionar un código de país.',
      invalidVatCfError: 'El valor debe ser alfanumérico y contener hasta 20 caracteres.',
      invalidItVatCfError: 'El valor debe constar de 11 dígitos (IVA) o 16 caracteres alfanuméricos (CF).',
      invalidItVatError: 'El valor debe tener 11 dígitos.',
      invalidItCfError: 'El valor debe constar de 16 caracteres alfanuméricos.'
    },
    select: {
      date: "Seleccione fecha",
      phonePrefix: "Seleccione país"
    },
    output: {
      code: 'Código HTML',
      copy: 'Copiar código',
      html_only: 'Solo HTML'
    },
    fields: {
      cant_exclude: "requerido",
      excluded: 'Campos excluidos',
      manage_visibility: 'Administrar la visibilidad',
      system_field: 'Campo del sistema',
      default_value: 'Valor estandar',
      default_value_instructions : 'Aquí puede configurar campos con valores predeterminados, \
      que se asignarán de forma predeterminada a todos los usuarios que completen su lista y \
      permanecerán ocultos durante la compilación. Son útiles para agregar información funcional \
      para la posterior gestión de tus contactos una vez que estén dentro de la lista. Ejemplo: \
      puedes proporcionar un valor predeterminado de opción múltiple que te permita asignar un \
      estado a los usuarios después de N días dentro de una lista: "cliente" si han realizado \
      compras o "abandono" si se han convertido en clientes fríos.'
    },
    messages: {
      confirmation_message: 'Mensaje de confirmacion',
      optin_confirmation_message: 'Mensaje de confirmación para opt in',
      update_confirmation_message: 'Mensaje de confirmación para actualizar preferencias',
      thank_you_page: 'Pagina de gracias',
      thank_you_page_help_valid_url: 'Asegúrese de ingresar una URL válida',
      thank_you_page_help_priority: 'Si se proporciona la URL de la página de agradecimiento, tendrá prioridad sobre el mensaje de confirmación.'
    },
    subscription: {
      ok: 'Suscrito con éxito a la lista.',
      error: {
        address: {
          already_exists: 'Lo sentimos, este correo electrónico ya está en la lista de suscriptores'
        },
        phone_number: {
          already_exists: 'Lo sentimos, este número de teléfono ya está en la lista de suscriptores'
        }
      }
    },
    generic: {
      clear: 'Reiniciar',
      error: 'Error',
      code_copied: 'Código copiado!',
      design: 'Design',
      fields: 'Campos',
      messages: 'Mensajes',
      customize: 'Personalizar el formulario',
      output: 'Risultado',
      preview: 'Avance',
      code: 'Exportar',
      save: 'Guardar',
      alignment: 'Alineación',
      width: 'Ancho',
      borderColor: 'Color del borde',
      borderWidth: 'Ancho del borde',
      borderRadius: 'Radio del borde',
      backgroundColor: 'Color de fondo',
      selectItemHoverColor: 'Seleccione el color de fondo de las opciones al pasar el mouse',
      selectItemActiveColor: 'Color de fondo de opciones de selección activa al pasar el mouse',
      selectItemColor: 'Color de las opciones seleccionadas',
      textColor: 'Color de texto',
      validation: {
        test: 'prueba de validación',
      },
      padding: {
        y: 'Acolchado vertical',
        x: 'Acolchado horizontal',
      },
      margin: {
        y: 'Margen vertical',
      },
      font: {
        size: "Tamaño del texto",
      },
      email: 'Email',
      phone_number: 'Número de teléfono'
    },
  }
}
