<svelte:options accessors />

<script>
  import {custom} from '$/stores/store'
  import _ from '$/utils/translations'
  import {KEY_CODES} from '$/utils/keyCodes'
  import {COUNTRY_PREFIX} from '$/data/country_code.js'
  import Select from '@/ui/Select.svelte'
  import {onMount} from 'svelte'
  import {VATCF_REGEX} from '$/data/vatcf_regex.js'

  // Props
  export let value
  export let name
  export let typeInputTax
  export let currentFormIndex
  export let required
  export let placeholder
  export let maxlength
  export let inputRef

  // Data
  let inputCountryCode = null
  let inputCountryCodeRef = null
  let countryCode = null
  let errorMessage = false

  $: inputCountryCode = inputCountryCodeRef?.value?.toString()

  const getCountryAndDigitsVatCf = vatCfValue => {
    let country = null
    let digits = null

    if (vatCfValue) {
      const codeTax = vatCfValue.split('-')
			country = codeTax[0]
			digits = codeTax[1]
    }
    return {
      country: country,
      digits: digits
    }
  }

  const validateITVAT = digits => {
    // for Italy, validate VAT number (11 digits)
    return VATCF_REGEX.itVatRegex.test(digits)
      ? null
      : _('input.invalidItVatError')
  }

  const validateITCF = digits => {
    // for Italy, validate CF number (16 alphanumeric characters)
    return VATCF_REGEX.itCfRegex.test(digits)
      ? null
      : _('input.invalidItCfError')
  }

  const validateITVatCf = digits => {
    // for Italy, validate CF number (16 alphanumeric characters)
    return VATCF_REGEX.itCfRegex.test(digits) ||
      VATCF_REGEX.itVatRegex.test(digits)
      ? null
      : _('input.invalidItVatCfError')
  }

  const validateVatCf = value => {
    return VATCF_REGEX.generalVatCfRegex.test(value)
      ? null
      : _('input.invalidVatCfError')
  }

  export const checkValidity = () => {
    // set no errors (clean validation)
    errorMessage = null

    // length = 3 is the length of the prefix (eg: IT-)
    // so if required and there is no value or the value is just the prefix
    // means its not valid
    const valueIsJustPrefix = !value || value.length == 3
    if (valueIsJustPrefix) {
      return required ? false : true
    }

    const {country, digits} = getCountryAndDigitsVatCf(value)

    errorMessage = validateVatCf(value)

    if (country === 'IT' && errorMessage === null) {
      switch (typeInputTax) {
        case 'VAT':
          errorMessage = validateITVAT(digits)
          break

        case 'CF':
          errorMessage = validateITCF(digits)
          break

        case 'VAT_CF':
          errorMessage = validateITVatCf(digits)
          break
      }
    }

    return errorMessage === null
  }

  const clearChoiceSelect = e => {
    value = ''
  }

  const handleChoiceSelect = e => {
    if ($custom['html-only']) {
      value = e.target.value ? `${e.target.value.toUpperCase()}-` : ''
    } else {
      value = e.detail?.value ? `${e.detail.value.toUpperCase()}-` : ''
		}
	}

  const filterPaste = e => {
    const allowedChars = /^[A-Za-z0-9\-]+$/
    const clipboardData = e.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')

    // ensure the country code is selected before allowing paste
    if (!value || value.length < 3) {
      e.preventDefault()
      return
    }

    // check if pasted data contains only alphanumeric characters
    if (!allowedChars.test(pastedData)) {
      e.preventDefault()
      return
    }

    // check if the first 2 characters of the current value match the pasted data
    // (meaning the country code is copied as well)
    const currentPrefix = value.slice(0, 2)
    const pastedPrefix = pastedData.slice(0, 2)
    if (currentPrefix === pastedPrefix) {
      e.preventDefault()
      const modifiedPastedData = pastedData.slice(2)
      // insert the modified pasted data at the current caret position
      const beforePaste = value.slice(0, e.target.selectionStart)
      const afterPaste = value.slice(e.target.selectionEnd)

      value = beforePaste + modifiedPastedData + afterPaste
    }
  }

  const filterInput = e => {
    if (!value) return value

    const allowed = [
      ...KEY_CODES.numbers,
      ...KEY_CODES.letters,
      ...KEY_CODES.edit,
      ...KEY_CODES.motion
    ]

    // prevent non-alphanumeric input
    if (!allowed.includes(e.keyCode)) {
      e.preventDefault()
      return
    }

    // minimum caret position: 3 (after country code and hyphen)
    const minCaretPosition = 3

    // ensure caret doesn't move before the 3rd character, and we don't allow removal of country code
    // only to italian codes

    if (e.target.selectionStart < minCaretPosition + 1) {
      if ([KEY_CODES.left, KEY_CODES.backspace].includes(e.keyCode)) {
        e.preventDefault()
      } else {
        if (e.target.selectionStart < minCaretPosition &&
          e.target.selectionStart === e.target.selectionEnd) {
          e.target.selectionStart = value ? value.length + 1 : minCaretPosition
        } else {
          e.target.selectionStart = Math.max(
            e.target.selectionStart,
            minCaretPosition
          )
          e.target.selectionEnd = Math.max(
            e.target.selectionEnd,
            minCaretPosition
          )
        }
      }
    }
  }

  onMount(() => {
    // Set the country
    // take the country part from browser or from value
    if (!value) {
			const languageParts = navigator.language.split('-')
			countryCode = languageParts[1]
      ? languageParts[1].toUpperCase()
      : languageParts[0].toUpperCase()

      const prefixObj = COUNTRY_PREFIX.find(x => x.value.toUpperCase() === countryCode)
      if (prefixObj) {
        inputCountryCode.$set({
          value: prefixObj
        })
        value = `${prefixObj.value.toUpperCase()}-`
      }
    }
		else
		{
			countryCode = value.slice(0, 2).toUpperCase()
			const prefixObj = COUNTRY_PREFIX.find(x => x.value.toUpperCase() === countryCode)
      if (prefixObj) {
        inputCountryCode.$set({
          value: prefixObj
        })
      }
		}
  })
</script>

<div
  style="display: flex; flex-direction: row"
  class="select-taxcode {!$custom['html-only'] ? 'km-form-select' : ''}"
>
  <div class="km-form-select-taxcode">
    <Select
      name="country_code"
      standardHtmlSelect={$custom['html-only']}
      args={{
        searchable: true,
        items: COUNTRY_PREFIX,
        placeholder: _('select.phonePrefix'),
        inputAttributes: {name: 'countryCode'}
      }}
      bind:this={inputCountryCode}
      bind:value={inputCountryCodeRef}
      on:change={handleChoiceSelect}
      on:clear={clearChoiceSelect}
      --font-size="14px"
      --background={$custom['input-background-color']}
      --list-background={$custom['input-background-color']}
      --placeholder-color={$custom['input-text-color']}
      --selected-item-color={$custom['input-text-color']}
      --item-hover-bg={$custom['select-item-hover-bg']}
      --item-is-active-bg={$custom['select-item-is-active-bg']}
      --item-color={$custom['select-item-color']}
      --border-radius={0}
    />
  </div>

  <input
    class={!$custom['html-only'] ? 'km-form-input' : ''}
    id={`${inputRef && inputRef.name}-${currentFormIndex}`}
    bind:value
    style="width: 100%;
        background:{$custom['input-background-color']};
        border-color:{$custom['input-border-color']};
        border-width:{$custom['input-border-width'] +
      $custom['input-border-width-unit']};
        border-style:solid;
        border-radius:{$custom['input-border-radius'] +
      $custom['input-border-radius-unit']};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;"
    {placeholder}
    {required}
    {name}
    autocomplete="off"
    on:paste={filterPaste}
    on:keydown={filterInput}
    {maxlength}
  />
</div>
