import {COUNTRIES_LIST} from '$/data/phone_prefix.js'

const countriesCodeSelectOptions = COUNTRIES_LIST.map((country) => {
    return {
      label: country[0],
      value: country[1]
    }
  })

Object.freeze(countriesCodeSelectOptions)
export const COUNTRY_PREFIX = countriesCodeSelectOptions