<script>
	import { onMount, setContext } from 'svelte';
	import { reloadTranslations } from '$/utils/translations'
	import readSubscriberAndFields from '$/utils/readSubscriberAndFields'
	import { reloadStore } from '$/stores/store';
	import Form from '@/km_lists/embedded/Form.svelte'

	export let targetDataset;
	export let baseUrl;
	export let listCode;
	export let embeddedFormId;
	export let messageKey;
	export let currentFormIndex;

	setContext('isPublic', true)

	let fields = []
	let subscriber = null
	
	onMount(async () => {
		reloadTranslations(targetDataset);
		reloadStore(baseUrl, listCode, embeddedFormId, messageKey);
		const res = await readSubscriberAndFields(messageKey)
		subscriber = res.subscriber
		fields = res.fields
	});
</script>

<Form
	{fields}
	{subscriber}
	{embeddedFormId}
	{messageKey}
	{targetDataset}
	{currentFormIndex}
/>
