import { get } from 'svelte/store'
import { baseUrl, listCode, shutdown, currentLangCode } from '$/stores/store';
import httpRequest from '$/utils/httpRequest';
import _ from '$/utils/translations'

const { env } = __app

const MCHOICE_FIELDS = Object.freeze([
	'MCHOICE',
	'COUNTRIES_MCHOICE',
	'ITALIAN_STATES_MCHOICE'
])

/**
 * Gives back an array of form fields.
 * 
 * Each form field is an object like:
 *  {
 *    type: String, // the type of the field
 *    name: String, // the name of the field
 *    required: Boolean, // if the field is required or not
 *    choices: Array // the available choices
 *  }
 * 
 * Available types:
 *  - STRING
 *  - INTEGER
 *  - TEXT
 *  - SCHOICE
 *  - MCHOICE
 *  - PHONE_NUMBER
 *  - DATE
 * 
 * If the field type is 'SCHOICE' or 'MCHOICE', then choices will be populated
 * with an Array of choices to populate inputs like selects, radios or checkboxes
 */
async function getFormFields(subscriber=null) {
	let fields
	let response
	try {
		const serverUrl = get(baseUrl);
		const url = `${serverUrl}/${env.GET_FORM_FIELDS_URL}`.replace('<list_code>', get(listCode));
		response = await httpRequest({url, method: env.GET_FORM_FIELDS_METHOD })
		fields = response.data
	} catch (err) {
		if (err instanceof ServerError) {
			shutdown.set(true)
		}
		return
	}

	// init
	fields.forEach(field => {
		if (subscriber) {
			// if the field type is email or phone number, the only way to detect if phone number is the system value
			// we should check the field.variable
			if ('EMAIL' === field.type || ('PHONE_NUMBER' === field.type && 'phone_number' === field.variable)) {
				field.value = subscriber[field.type] !== 'None' && subscriber[field.type] ? subscriber[field.type] : ''
			}	else if ('PHONE_NUMBER' === field.type) field.value = subscriber[field.variable]
			else if ('CONSENT' === field.type) {
				// if the field is consent and is privacy (it means the default privacy field) it should be unchecked
				if (field.variable === 'privacy') field.value = false
				else {
					// if the field is required it should be unchecked because it need to be checked by the user
					if (field.required) field.value = false
					else {
						const acceptanceInfo = subscriber[field.variable]
						if (acceptanceInfo) {
							// if today date is greater than the expiration date
							// value should be false
							field.value = !acceptanceInfo.expires || (acceptanceInfo.expires && new Date() < new Date(acceptanceInfo.expires) )
						} else {
							field.value = false
						}
					}
				}
			}
			else {
				field.value = subscriber[field.variable] || ''
			}
		} else {
			if (MCHOICE_FIELDS.includes(field.type)) field.value = []
			else if (['CHECKBOX', 'CONSENT'].includes(field.type)) field.value = false
			else field.value = null
		}
	})

	return fields
}

export default getFormFields;
