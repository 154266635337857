export function camelCaseToHyphen(camelCase) {
  return camelCase.replace(/([A-Z])/g, '-$1').toLowerCase();
}

export function camelCaseToAttribute(camelCase) {
  return 'data-' + camelCaseToHyphen(camelCase);
}

export function getConfigFromDataset(dataset) {
    // in:  { buttonBackgroundColor: "red" }
    // out: { button-background-color: "red" }
    return Object.fromEntries(
        Object.entries(dataset).map(([key, value]) => 
            [camelCaseToHyphen(key), value]
        )
    )
}
